<template>
  <v-slide-group multiple show-arrows height="80">
    <slot></slot>
    <slot name="second-item"></slot>
    <v-slide-item class="mt-6 mx-2">
      <v-autocomplete label="Catégorie" :items="assets_categories" item-text="label" item-value="value"
                      v-model="selected_category"></v-autocomplete>
    </v-slide-item>

    <v-slide-item class="mt-6">
      <v-text-field label="Nom" v-model="search_name"></v-text-field>
    </v-slide-item>
    <v-slide-item class="mt-6 mx-2">
      <v-btn text color="red" @click="$store.commit('assets/resetFilters')">
        <v-icon>mdi-cancel</v-icon>
        Retirer
      </v-btn>
    </v-slide-item>
  </v-slide-group>
</template>

<script>

export default {
  name: "AssetFilter",
  async mounted() {
    this.$store.commit("assets/resetFilters");
    if (this.$store.getters['sites/allSites'].length === 0) {
      await this.$store.dispatch('sites/fetchSites');
    }
  },
  data() {
    return {
      site_dialog: false,
    }
  },
  computed: {
    assets_categories() {
      return this.$store.getters["assets/allAssetsCategories"];
    },
    assets_sites() {
      return this.$store.getters["assets/getAssetsSites"];
    },
    sites_tree() {
      return [{id: "", name: "Tous les sites"}, ...this.$store.getters["sites/sitesTree"]];
    },
    search_name: {
      get() {
        return this.$store.getters["assets/getSearchName"];
      },
      set(value) {
        return this.$store.commit("assets/setSearchName", value)
      }
    },
    selected_category: {
      get() {
        return this.$store.getters["assets/getFilterCategory"];
      },
      set(value) {
        return this.$store.commit("assets/setFilterCategory", value)
      }
    },
    selected_site: {
      get() {
        return this.$store.getters["assets/getFilterSite"];
      },
      set(value) {
        return this.$store.commit("assets/setFilterSite", value)
      }
    },

  },
  destroyed() {
    this.$store.commit("assets/resetFilters");
  }
}
</script>

<style scoped>

</style>