<template>
  <v-container fluid class="pa-0">
    <v-toolbar>
      <AssetFilter>
        <v-slide-item class="mt-6">
          <v-btn v-if="isResponsableOrAdmin || hasPermission('add_asset')" @click="switchDrawer()" color="primary">Nouvel équipement</v-btn>
        </v-slide-item>
        <template v-slot:second-item>
          <v-slide-item>
            <SiteTreeSelector v-model="assets_site_tree" />
          </v-slide-item>
        </template>
      </AssetFilter>
    </v-toolbar>
    <v-row class="ma-0">
      <v-col order-md="1" order="2" cols="12" md="5" id="asset-list">
        <AssetList :assets-loaded="assetsLoadedOrNotEmpty"/>
      </v-col>
      <v-col order-md="2" order="1" md="7" cols="12" id="asset-view">
        <router-view @edit-asset="editAsset()"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AssetList from "@/components/assets/AssetList";
import NewAssetSidebar from "@/components/assets/NewAssetSidebar";
import AssetFilter from "@/components/assets/AssetFilter";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import SiteTreeSelector from "@/views/SiteTreeSelector";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

export default {
  name: "Assets.vue",
  components: {SiteTreeSelector, AssetFilter, AssetList},
  mixins: [AuthMixin, DrawerMixin],
  async mounted() {
    if (this.$store.getters["assets/allAssets"].length === 0) {
      await this.$store.dispatch("assets/fetchAssets");
    }
    this.assetsLoaded = true;
    if (this.$store.getters["categories/allCategories"].length === 0) {
      this.$store.dispatch("categories/fetchCategories");
    }
    if (this.$store.getters["groups/groups"].length === 0) {
      this.$store.dispatch("groups/fetchGroups");
    }
    if(this.$route.query.new !== undefined && this.$route.query.new === "true") {
      this.switchDrawer();
    }
  },
  data: function () {
    return {
      dialog: false,
      operation: "create",
      assetToUpdate: {},
      asset_id: 0,
      assetsLoaded: false,
    }
  },
  methods: {
    editAsset() {
      this.operation = "update";
      this.assetToUpdate = this.selected_asset;
      this.dialog = true;
      this.asset_id = this.selected_asset.id;
      let properties = {groups:this.groups_intervenant, operation: "update", key:this.selected_asset.id,
                       "asset-to-update":this.selected_asset};
      this.openDrawerDialog(NewAssetSidebar, properties);
    },
    switchDrawer() {
      let properties = {groups:this.groups_intervenant, operation:this.operation, key:this.asset_id,
                       "asset-to-update":this.assetToUpdate};
      this.openDrawerDialog(NewAssetSidebar, properties);

    },
    closeDrawer() {
      this.operation = "create";
      this.assetToUpdate = null;
      this.dialog = false;
      this.asset_id = -1;
    },
  },
  computed: {
    selected_asset() {
      return this.$store.getters["assets/getSelectedAsset"];
    },
    groups_intervenant() {
      return this.$store.getters["groups/groups_intervenant"];
    },
    assetsLoadedOrNotEmpty() {
      return this.$store.getters["assets/allAssets"].length !== 0 || this.assetsLoaded;
    },
    assets_site_tree: {
      get() {
        return this.$store.getters["assets/getFilterSiteTree"];
      },
      set(value) {
        return this.$store.commit("assets/setFilterSiteTree", value)
      }
    },
  }
}
</script>

