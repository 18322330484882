<template>
  <v-data-iterator :no-data-text="no_results_text" loading-text="Chargement des équipements en cours..." :loading="!assetsLoaded" :items="$store.getters['assets/getFilteredAssets']" item-key="id" :items-per-page="5">
    <template v-slot:default="{ items }">
      <v-card @click="setAsset(asset)" outlined elevation="0" v-for="asset in items" :key="asset.id">
        <div class="pa-3">
          <v-row no-gutters class="pl-3 mb-2">
            <v-col class="pa-0 ma-0">
              <h3 class="blue--text text--darken-3">{{ asset.name }}</h3>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="pa-0 ma-0 d-flex">
              <h3 style="text-align: end">{{asset.balise}}</h3>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="12" class="pa-0 ma-0">
            <span v-if="asset.site !== null" class="grey--text">
              <v-icon left>mdi-map-marker</v-icon>
              <span v-if="asset !== null">{{ asset.site.name }}</span>
              <span v-if="asset.site.parent !== null">, {{ asset.site.parent }}</span>
              <span v-if="asset.site.top !== null">, {{ asset.site.top }}</span>
              <span v-if="asset === null" class="font-italic grey--text">Non défini</span>
            </span>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="12" md="3">
              <v-chip color="primary" v-if="asset.category !== null">
                <span class="white--text">{{ asset.category.name }}</span>
              </v-chip>
            </v-col>
            <v-col cols="12" md="9">
              <span class="font-italic" v-if="asset.nombre_interventions !== 0">{{asset.nombre_interventions}} intervention(s)</span>
              <span class="font-italic" v-else>Aucune intervention réalisée</span><br>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </template>
  </v-data-iterator>
</template>
<script>

import dayjs from "dayjs";

export default {
  name: 'AssetList',
  props: {
    assetsLoaded: Boolean,
  },
  computed: {

    assets() {
      return this.$store.getters["assets/getFilteredAssets"]
    },
    no_results_text() {
      let filter_category = this.$store.getters["assets/getFilterCategory"];
      let filter_site = this.$store.getters["assets/getFilterSite"]
      let search_name = this.$store.getters["assets/getSearchName"]
      if(!search_name && !filter_site && !filter_category) {
        return "Veuillez appliquer un des filtres pour voir les équipements.";
      } else {
        return "Aucun équipement disponible."
      }
    }
  },
  methods: {
    last_intervention(asset) {
      return this.$store.getters["interventions/getLastIntervention"](asset);
    },
    last_intervention_date(asset) {
      let intervention = this.$store.getters["interventions/getLastIntervention"](asset);
      return intervention !== null ? dayjs(intervention.date_debut).format("DD/MM/YY") : null;
    },
    async setAsset(asset) {
      this.$store.commit("assets/setSelectedAsset", asset);
      await this.$store.dispatch("tickets/fetchTicketsByAsset", asset);
      const path = `/assets/${asset.id}`;
      if (this.$route.path !== path) this.$router.push(path);
      this.$vuetify.goTo(0);
    }
  }
}
</script>
